<template>
  <div>
    <div class="text-right mt-4">
      <button
        @click="setModal()"
        class="btn btn-pill btn-default font-weight-bold"
      >
        <i class="icon-plus"></i> Alan Ekle
      </button>
    </div>
    <Table :loading="loading" :headers="headers" :items="items"> </Table>
    <Modal @ok="POST_DEFINITION" v-model="showForm">
      <div class="row">
        <div class="col-12 my-2 form_field">
          <label for="label" class="col-2">{{ this.$t("title") }}:</label>
          <input v-model="form.label" id="label" type="text" class="col-10" />
        </div>
        <div class="col-12 my-2">
          <label for="tag" class="col-2">{{ this.$t("tag") }}:</label>
          <select v-model="form.tag" id="tag" class="col-4">
            <option value="input">Input</option>
            <option value="select">Select</option>
          </select>
        </div>
        <div class="col-12 my-2">
          <label for="type" class="col-2">{{ this.$t("type") }}:</label>
          <select
            :disabled="form.tag === 'select'"
            v-model="form.type"
            id="type"
            class="col-4"
          >
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="phone">Phone</option>
            <option value="email">E-mail</option>
          </select>
        </div>
        <div class="col-12 my-2">
          <label class="ml-3">{{ this.$t("validation") }}:</label> <br />
          <div class="row ml-0 mt-1">
            <div class="col-4">
              Required <input v-model="isRequired" type="checkbox" />
            </div>
            <div class="col-4">
              Min. Length <input v-model="showMinLength" type="checkbox" />
              <input
                type="text"
                v-model="minLength"
                v-if="showMinLength"
                class="col-8"
              />
            </div>
            <div class="col-4">
              Max. Length <input v-model="showMaxLength" type="checkbox" />
              <input
                type="text"
                v-model="maxLength"
                v-if="showMaxLength"
                class="col-8"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
    {{ definitions }}
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import Modal from "../Shared/Modal/index.vue";

export default {
  components: {
    Table,
    Modal,
  },
  data() {
    return {
      showForm: false,
      isRequired: false,
      showMaxLength: false,
      maxLength: "",
      showMinLength: false,
      minLength: "",
      form: {
        label: "",
        tag: "",
        type: "",
        validations: "",
        options: "",
      },
      definitions: {},
      headers: [
        {
          title: this.$t("title"),
          value: "title",
          col: 12,
          md: 3,
          lg: 3,
          xl: 2,
        },
        {
          title: this.$t("tag"),
          value: "tag",
          col: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("type"),
          value: "type",
          col: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("validation"),
          value: "validations",
          col: 6,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("options"),
          value: "options",
          col: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
      ],
      items: [],
      loading: false,
    };
  },

  methods: {
    setModal() {
      this.showForm = true;
      (this.isRequired = false),
        (this.maxLength = ""),
        (this.minLength = ""),
        (this.form = {
          label: "",
          tag: "",
          type: "",
          validations: "",
          options: "",
        });
    },
    async GET_DEFINITIONS() {
      this.loading = true;
      const response = await this.$api.get(
        `Definitions?useType=${this.$route.params.type}&lang=${this.$i18n.locale}`
      );
      if (response.message === "OK") {
        this.definitions = response.data;

        for (const i in this.definitions) {
          this.items.push({
            title: this.definitions[i].label,
            tag: this.definitions[i].tag,
            type: this.definitions[i].type,
            validations: this.definitions[i].validations,
            options: this.definitions[i].options,
          });
        }
      }
      this.loading = false;
    },
    async POST_DEFINITION(loading) {
      loading(true);
      this.loading = true;
      if (this.isRequired) {
        this.form.validations += "required,";
      }
      if (this.showMaxLength) {
        this.form.validations += `maxLength(${this.maxLength}),`;
      }
      if (this.showMinLength) {
        this.form.validations += `minLength(${this.minLength}),`;
      }
      const response = await this.$api.post("Definitions", {
        tag: this.form.tag,
        type: this.form.type,
        label: this.form.label,
        validations: this.form.validations,
        options: this.form.options,
      });
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
        this.GET_DEFINITIONS();
      } else {
        this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
      }
      loading(false);
    },
  },
  mounted() {
    this.GET_DEFINITIONS();
  },
};
</script>

<style scoped></style>
